$breakpoints: (
  "xs": 420px,
  "sm": 540px,
  "md": 750px,
  "lg": 992px,
  "xl": 1200px,
  "xxl": 1400px,
) !default;

$f-base: 16px/1.4 "Oswald", sans-serif;
$f-title: "Oswald", sans-serif;
$f-color: #555;

$l-g: 15px;
$lg: $l-g;

$sp: 20px;

// $c-main: $c-text;
$c-brand: var(--c-brand);
$c-sec: var(--c-sec);
$c-dop: #0d80d3;
$c-bg: var(--c-bg);
$c-text: var(--c-text);
$c-link: inherit;
$c-active: $c-brand;

$hc-offcanvas-nav-background-color: $c-brand;
$hc-offcanvas-nav-text-size: 16px;

$container-max-widths: (
  xxl: 1400px,
);

@include font("Oswald", "Oswald-Regular", 400);
@include font("Oswald", "Oswald-Medium", 500);
