.intro__slider {
  // height: 170px
  overflow: hidden; }

.swiper-slide .intro__slide {
  opacity: 0; }
.swiper-slide-prev .intro__slide {
  opacity: 1;
  animation: introout 1s both; }
.swiper-slide.swiper-slide-active .intro__slide {
  opacity: 1;
  animation: introin 1s both; }

@keyframes introin {
  0% {
    transform: translateY(100%);
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateY(0%); } }

@keyframes introout {
  0% {
    transform: translateY(0);
    opacity: 1; }
  100% {
    opacity: 1;
    transform: translateY(-100%); } }

.swiper-arrow {
  position: relative;
  bottom: 0;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  &--small {
    display: flex;
    justify-content: center; }
  .swiper-button-prev,
  .swiper-button-next {
    font-size: 24px;
    line-height: 0;
    color: #fff;
    padding: 0 .5em; }
  .swiper-button-prev {
    transform: rotate(180deg); } }

.intro__slide {
  font-weight: 500;
  font-size: 28px;
  // padding: 0 20px
  height: 100%;
  display: flex;
  align-items: center;
  @include r(sm) {
    font-size: 36px; }
  @include r(lg) {
    font-size: 48px; } }

.swiper-slide.swiper-slide-active  .feature-slide {
  animation: feature-in 1s both; }

@keyframes feature-in {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateY(0); } }


.swiper-slide.swiper-slide-active  .feature-slide__title {
  animation: feature-cnt 1.5s both; }

.swiper-slide.swiper-slide-active  .feature-slide__txt {
  animation: feature-cnt 1.5s both; }

@keyframes feature-cnt {
  0% {
    opacity: 0;
    transform: translateY(100%); }
  100% {
    opacity: 1;
    transform: translateY(0); } }


.feature-sliders .swiper-slide {
  height: auto; }

.feature-sliders {
  position: relative;
  &__el {
    position: absolute;
    height: 100%;
    right: 0;
    z-index: 2;
    @include r(1270) {
      right: calc( (100vw - 1270px) / 2); }
    @include rmax(md) {
      display: none; } } }

.feature-slide {
  position: relative;
  padding: 40px 0;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  transition: all .3s;
  @include rmax(md) {
    padding: 40px 0 80px; }
  &__title {
    font-weight: 500;
    margin-bottom: .5em;
    transition: all .5s;
    font-size: 24px;
    @include r(xs) {
      font-size: 36px; }
    @include r(lg) {
      font-size: 48px; } }
  // &__txt
  //   transition: all .5s
  &__cnt {
    font-weight: 500;
    font-size: 16px;
    @include r(xs) {
      font-size: 24px; }
    @include r(md) {
      margin-right: 25%; }
    @include r(lg) {
      font-size: 36px; } } }

.feature-sliders .swiper-arrow {
  @include r(md) {
    display: none; } }

.feature-slide.slide0 {
  background-image: url('/img/bg/bg-feuture-0.jpg');
  color: $c-text; }

.feature-slide.slide1 {
  background-image: url('/img/bg/bg-feuture-1.jpg'); }

.feature-slide.slide2 {
  background-image: url('/img/bg/bg-feuture-2.jpg'); }

.feature-slide.slide3 {
  background-image: url('/img/bg/bg-feuture-3.jpg'); }

.feature-slide.slide4 {
  background-image: url('/img/bg/bg-feuture-4.jpg'); }

.feature-slide.slide5 {
  background-image: url('/img/bg/bg-feuture-5.jpg');
  color: $c-text; }

.feature-slide.slide6 {
  background-image: url('/img/bg/bg-feuture-6.jpg'); }

.feature-slide.slide7 {
  background-image: url('/img/bg/bg-feuture-7.jpg');
  color: $c-text; }

.feature-slide.slide8 {
  background-image: url('/img/bg/bg-feuture-8.jpg'); }

.feature-slide.slide9 {
  background-image: url('/img/bg/bg-feuture-9.jpg'); }

.feature-count {
  position: absolute;
  right: 40px;
  z-index: 2;
  height: 200px;
  top: 50%;
  transform: translateY(-50%); }

.slider-count,
.feature-count {
  @include rmax(md) {
    display: none; } }

.inp-range {
  // .noUi-base, .noUi-connects

  &.noUi-vertical .noUi-handle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: none;
    background-color: $c-text;
    box-shadow: none;
    right: -8px; }

  &.noUi-target {
    width: 10px;
    border: 2px solid $c-text;
    border-radius: 10px;
    background-color: transparent; }

  &.noUi-vertical .noUi-handle:after,
  &.noUi-vertical .noUi-handle:before {
    content: none; }

  .noUi-tooltip {
    font-weight: bold;
    background-color: transparent;
    color: $c-text;
    border: none; } }


