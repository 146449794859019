.link-block {
  text-decoration: none;
  color: inherit;
}

%cf {
  &:before {
    content: "";
    display: table;
    clear: both;
  }
}

.hidden {
  display: none;
}

.disable {
  opacity: 0.5;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.svg-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
}
