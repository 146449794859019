.btn {
  display: inline-block;
  background-color: $c-sec;
  border-radius: .5em;
  padding: .2em 2em 0.27em;
  text-decoration: none;
  line-height: 1;
  border: none;
  color: #0d1368;
  transition: all .3s;
  &:hover {
    color: #0d1368;
    background-color: #F5D35C; }
  &:active {
    background-color: #E8A20C; }
  &--big {
    font-size: 24px;
    padding: .5em 3em 0.75em; }
  &--full {
    text-align: center;
    display: block;
    padding: .75em 3em; }
  &--outline {
    background-color: $c-bg;
    color: $c-text;
    border: 1px solid;
    &:hover {
      color: $c-text;
      opacity: 0.6;
      background-color: $c-bg; }
    &:active {
      opacity: 1;
      color: #071194; } } }


//castum select
.custom-select-container {
  position: relative;
  box-sizing: border-box; }

.custom-select-container * {
  box-sizing: border-box; }

.custom-select-container.is-disabled {
  opacity: .333; }

.custom-select-opener {
  background-color: var(--c-sec);
  border-radius: .5em;
  padding: 0.5em;
  display: block;
  cursor: pointer;
  width: 100%; }

.custom-select-container select {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0; }

.custom-select-panel {
  max-height: 0;
  transition: max-height .5s ease-out, overflow-y 0.1s 0.5s;
  overflow: hidden;
  background-color: $c-bg;
  position: absolute;
  top: 100%;
  z-index: 1;
  width: 100%; }

.custom-select-container.is-open .custom-select-panel {
  max-height: 10.7em;
  overflow-y: auto; }

.custom-select-option {
  padding: 0.5em; }

.custom-select-option.has-focus {
  background-color: LightBlue; }

.custom-select-optgroup > .custom-select-option {
  padding-left: 2em; }

.custom-select-optgroup::before {
  content: attr(data-label);
  display: block;
  padding: 0.5em;
  color: #888; }

.custom-select-option {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.lang-ico {
  width: 24px; }

.slang {
  width: 85px;
  position: relative;
  .custom-select-panel {
    width: 160px;
    box-shadow: 0 3px 5px rgba(#000, .3); }

  .custom-select-opener {
    position: relative;
    display: flex;
    padding: 3px 30px 3px 10px;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    &:before {
      content: "";
      position: absolute;
      right: 10px;
      top: 50%;
      width: 11px;
      height: 6px;
      background: url('/img/icon/arr-down.svg') no-repeat 50% 50%;
      transform: translateY(-50%); } } }

.form {
  background: url("/img/bg_form.jpg") no-repeat;
  background-size: cover;
  padding: 40px;
  max-width: 600px;
  &__title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 1.5em; }
  input:not([type="submit"]) {
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #000;
    background-color: transparent;
    &:focus {
      outline: none; } }
  .btn {
    padding: 1em 2em;
    font-size: 18px;
    margin: 10px;
    &--outline {
      background-color: transparent; } }
  .btn-wrap {
    text-align: center;
    margin: 1em -1em; } }



.form-line {
  margin-bottom: 1.5em; }

.fancybox__backdrop {
  background: $c-bg;
  opacity: .7!important; }

body.compensate-for-scrollbar .page {
  filter: blur(10px); }

.form-ajax {
  display: none;
  box-shadow: 0 0 10px rgba(#000, .5);
  border-radius: 30px; }

.form-answer {
  display: none;
  font-size: 24px;
  text-align: center;
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(#000, .5); }
