.nav {
  display: flex;

  &__item {
    list-style: none;
    font-size: 18px; }

  &__link {
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: inherit;
    overflow: hidden;
    &:hover {
      color: inherit;
      &::before {
        transform: translateX(0); } }
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      transition: all .3s;
      background-color: $c-text;
      transform: translateX(-100%); } } }

.link-color {
  color: $c-sec; }

.header-page .menu,
.header-page .stheme,
.header-page .slang {
  @include rmax(lg) {
    display: none; } }

.burger {
  display: none;
  cursor: pointer;
  margin: 0 auto;
  padding-left: 1.25em;
  position: fixed;
  top: 15px;
  right: 30px;
  width: 24px;
  height: 24px;
  padding: 5px;
  transition: all .3s;
  z-index: 1010;
  border: 1px solid transparent;
  @include rmax(lg) {
    display: block; }
  span {
    backface-visibility: hidden;
    position: absolute;
    left: 0;
    border-top: 2px solid $c-text;
    width: 100%;
    transition: 0.55s;
    top: 50%;
    &:nth-child(1) {
      margin-top: -6px; }
    &:nth-child(3) {
      margin-top: 6px; } } }

.mob-open {
  .burger {
    border: 1px solid $c-text;
    border-radius: 50%;
    span:nth-child(1) {
      transform: rotate(45deg);
      margin: 0; }
    span:nth-child(2) {
      opacity: 0; }
    span:nth-child(3) {
      transform: rotate(-45deg);
      margin: 0; } } }

.header-page__side {
  position: relative; }

.mobNav {
  position: fixed;
  z-index: 1001;
  right: 0;
  top: 0;
  transform: translateY(-100%);
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column;
  max-width: 320px;
  background-color: #fff;
  box-shadow: 0 3px 5px rgba(#000, .3);
  padding: 20px;
  transition: all .3s;
  @include r(lg) {
    display: none; }
  .nav {
    padding: 0;
    flex-direction: column; }
  .nav__item {
    padding: .5em 0;
    margin: 0; }
  .header-page__side-item {
    margin-left: 0; } }

.mob-open .mobNav {
  transform: translateY(0); }
