html {
  scroll-behavior: smooth; }

body {
  display: flex;
  flex-direction: column; }

h1, h2, h3, h4, strong {
  font-weight: 500; }

.svg-icon {
  width: 1em;
  height: 1em;
  fill: currentColor;
  font-size: inherit; }

.page {
  flex: 1;
  overflow: hidden; }

.container {
  max-width: 1270px;
  padding: 0 20px;
  margin: 0 auto; }

.section {
  padding: 40px 0;
  @include r(lg) {
    padding: 100px 0; } }

.header-wrap {
  position: fixed;
  width: 100%;
  z-index: 1000;
  transition: all .2s;
  @include rmax(lg) {
    padding-right: 60px; }
  &.stick {
    background-color: $c-bg; } }

.header-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  &__side {
    display: flex;
    align-items: center; }
  &__side-item {
    margin-left: 40px; }
  &__logo {
    flex: 0 0 auto;
    &:focus {
      outline: none; } }
  .nav__item {
    margin: 0 2vw;
    line-height: 1; } }

.theme-swith {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 26px;
  background: $c-sec url(/img/theme-switсh.svg) no-repeat 50% 50%;
  cursor: pointer;
  border-radius: 5px;
  transition: all .3s;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 22px;
    height: 100%;
    transform: translateX(60px-22px);
    transition: all .3s;
    border-radius: 5px;
    background-color: $c-text; } }

.text-center {
  text-align: center; }

.intro {
  position: relative;
  min-height: 100vh;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10vh 0;
  background-color: $c-bg;
  &__content {
    position: relative;
    z-index: 1;
    width: 100%; }
  &__content-item {
    &:not(first-child) {
      margin-top: 80px; }
    &--main {
      display: grid;
      grid-gap: 40px;
      align-items: center;
      @include r(md) {
        grid-gap: 60px;
        grid-template-columns: auto 1fr auto; } } }
  &__pre-title {
    color: $c-brand;
    font-weight: 500;
    font-size: 30px;
    @include r(sm) {
      font-size: 42px; }
    @include r(md) {
      text-align: right;
      font-size: 48px;
      span {
        display: block; } }
    @include r(lg) {
      font-size: 64px; } }
  &__watch {
    font-size: 24px; }
  .slider-count {
    height: 100%; }
  // .swiper-wrapper
 }  //   height: 170px

.bg-video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; }

.features {
  background: url(/img/bg_feature.jpg) no-repeat 50% 0;
  background-size: cover; }


.features-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px 40px;
  @include r(xs) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px; }
  @include r(md) {
    grid-gap: 100px;
    grid-template-columns: repeat(3, 1fr); } }

.feature {
  &__title {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0 0 .5em 0;
    // padding-right: 60px
    font-weight: 500;
    line-height: 1.2;
    color: $c-brand;
    font-size: 20px;
    @include r(lg) {
      font-size: 36px;
      align-items: flex-start; } }
  &__icon {
    margin-left: 1em;
    flex: 0 0 40px;
    @include r(lg) {
      flex: 0 0 auto; }

    // position: absolute
    // right: 0
 }    // top: 0
  &__txt {
    @include r(lg) {
      font-size: 24px; } } }

.plans {
  position: relative;
  &__bgtitle-1,
  &__bgtitle-2 {
    position: absolute;
    color: #eee;
    z-index: -1;
    @include resfont(64, 20);
    @include rmax(lg) {
      display: none; } }
  &__bgtitle-1 {
    top: 0;
    left: 5vw; }
  &__bgtitle-2 {
    right: 5vw; } }

.plan-wrap {
  display: grid;
  grid-gap: 30px;
  @include r(md) {
    grid-template-columns: repeat(3, 1fr ); } }

.plan {
  padding: 30px;
  background-color: $c-bg;
  box-shadow: 0px 6px 6px 6px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  border: 1px solid #e4e1e1;
  display: flex;
  flex-direction: column;
  max-width: 360px;
  margin: 0 auto;

  &__title {
    margin: 0 0 1em;
    font-size: 24px;
    font-weight: 400; }
  &__icon {
    margin-left: .5em; }
  &__desc {
    color: #B7B7B7;
    margin-bottom: 1em;
    text-transform: uppercase; }
  &__feature {
    margin-bottom: 1em; }
  &__footer {
    margin-top: auto; }
  &__footer-desc {
    margin-top: 20px;
    text-align: center;
    color: #b7b7b7; }
  &.disabled {
    .plan__footer-btn .btn {
      cursor:  not-allowed;
      background: #fff;
      border: 1px solid $c-text;
      color: $c-text; } } }

.price {
  font-size: 24px;
  &__val {
    vertical-align: middle;
    font-size: 36px;
    margin: 0 .25em;
    font-weight: bold; } }

.list-feature {
  margin: 0 0 1em 0;
  padding: 0;
  list-style: none;
  &__item {
    margin-bottom: 1em;
    padding-left: 2em;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 2em;
      height: 2em;
      background: url('/img/icon/list.svg') no-repeat; } } }

.footer-page {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(183, 183, 183, 0.5) 100%);
  font-size: 16px;
  @include r(sm) {
    font-size: 24px; }
  .nav {
    flex-direction: column;
    margin: 0;
    padding: 0;
    .nav__item {
      font-size: inherit;
      &:not(:last-child) {
        margin-bottom: 1em; } } }

  &__main-wrap {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(2, auto);
    @include r(lg) {
      grid-gap: 80px;
      grid-template-columns: repeat(4, auto); } }
  &__main {
    @include r(lg) {
      display: flex;
      flex-direction: column; }
    @include rmax(lg) {
      grid-column: 1 / -1; } }
  &__nav-service {
    @include rmax(lg) {
      grid-column: 2 /2; } }
  &__nav-main {
    @include rmax(lg) {
      grid-column: 1 / 1;
      grid-row: 2; } }
  &__soc {
    margin-top: 3em;
    max-width: 360px;
    @include rmax(lg) {
      margin: 0 auto;
      grid-column: 1 / -1;
      grid-row: 3;
      align-content: center; } }
  &__contact {
    p {
      margin-top: 0; }
    a {
      text-decoration: none; }
    @include rmax(lg) {
      grid-column: 1 / -1;
      display: grid;
      grid-gap: 40px;
      grid-template-columns: 1fr 1fr; } } }

.copy {
  margin: auto 0 0;
  font-size: 16px;
  padding: 20px 0 .25em;
  @include rmax(lg) {
    text-align: center;
    grid-column: 1 / -1; } }

.soc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__link {
    display: inline-block;
    font-size: 36px;
    color: $c-brand;
    text-decoration: none;
    box-shadow: 2px 2px 4px rgba(#000, .3);
    line-height: 0;
    border-radius: 10px;
    transition: all .3s;
    &:not(:first-child) {
      margin-left: 1em; }
    &:hover {
      color: $c-brand;
      transform: scale(1.1); } } }

