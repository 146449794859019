.faq-inro {
  text-align: center;
  margin-bottom: 60px;
  &__icon {
    margin: 0 auto 30px;
    width: 200px; }
  &__title {
    font-size: 36px;
    font-weight: 500;
    margin: 0 1em 0 0;
    padding: 0;
    &.title {
      color: $c-text; } }
  &__subtitle {
    font-size: 36px;
    color: #999999; } }

.questions {
  max-width: 1000px;
  margin: 0 auto;
  &__item:not(:last-child) {
    margin-bottom: 24px; } }

.qestion-title {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
  color:  #0d1368; }
.qestion-txt {
  font-size: 18px; }

.accordion {
  &__title {
    position: relative;
    background-color: $c-sec;
    padding: .5em 2.5em .5em 2em;
    border-radius: 20px 20px 0 0;
    cursor: pointer;
    &:after {
      content: "";
      position: absolute;
      right: 1.5em;
      top: 1em;
      width: 1em;
      height: 1em;
      background: url("/img/icon/arr-down.svg") no-repeat 50% 50%;
      background-size: contain;
      transition: transform .2s; }
    &.active {
      &:after {
        transform: rotate(180deg); } } }
  &__txt {
    padding: 1em;
    p {
      margin-top: 0;
      &:last-child {
        margin-bottom: 9px; } } }
  &__panel {
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 20px 20px;
    max-height: 0;
    overflow: hidden;
    transition: all .3s ease-in-out; } }

.accordion__title.active + .accordion__panel {
  display: block;
  max-height: 1000px; }


