* {
  box-sizing: border-box; }


@import "mixin/**/*";
@import "_theme";
@import "_var";
// @import "bootstrap/scss/functions"
// @import "bootstrap/scss/variables"
// @import "bootstrap/scss/mixins"
// @import "bootstrap/scss/grid"

// @import "_sprite-symb"
@import './nouislider.min.scss';
// @import "../js/plugin/slick/slick.scss"
@import 'swiper/swiper';
@import 'swiper/components/effect-fade/effect-fade.scss';
@import 'swiper/components/effect-fade/effect-fade.scss';
@import '@fancyapps/ui/src/Fancybox/fancybox';

@import "base/_normalize";
@import "base/_base";
@import "base/_helper";
// @import "vendor/_grid-m"
// // @import "vendor/_jquery.formstyler.scss"

@import "block/**/*";
