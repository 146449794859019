.title {
  margin: 0 0 50px 0;
  color: $c-brand;
  font-size: 28px;
  font-weight: 500;
  @include r(lg) {
    margin: 0 0 100px 0;
    font-size: 46px; } }
.subtitle {
  margin:  -50px 0 50px 0;
  font-weight: 500;
  font-size: 24px;
  @include r(lg) {
    margin:  -100px 0 100px 0;
    font-size: 36px; } }

// *
//   outline: 1px solid
