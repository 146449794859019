body {
  --c-brand: #e41139;
  --c-text: #0d1368;
  --c-sec: #ffc700;
  --c-bg: #fff;
}

.theme-dark {
  background-color: #2d333c;
  --c-bg: #2d333c;
  --c-text: #d6d7daec;
  --c-brand: #e41139;
  color: var(--c-text);
  .theme-swith {
    background-color: #2d333c;
    &:before {
      transform: translateX(0);
      background-color: var(--c-sec);
    }
  }
  .bg-video {
    opacity: 1;
    mix-blend-mode: multiply;
  }
  .plans__bgtitle-1,
  .plans__bgtitle-2 {
    opacity: 0.15;
  }
  .features {
    background-color: var(--c-bg);
    background-blend-mode: color-burn;
  }
  .feature-slide {
    background-color: var(--c-bg);
    color: var(--c-text) !important;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(#2d333c, 0.65);
      mix-blend-mode: multiply;
    }
  }
  .footer-page {
    background: linear-gradient(var(--c-bg), #000);
  }
  .custom-select-option.has-focus {
    color: #2d333c;
  }
  .slang .custom-select-opener{
    color: #2d333c;// background: url("/img/icon/arr-down__light.svg") no-repeat 50% 50%;
  }
  .form-ajax .btn--outline,
  .form-ajax .btn--outline:hover {
    color: #2d333c;
  }
}
